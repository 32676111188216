<template>
  <div class="wrapper" v-if="!showEmployers">

    <div class="row">

      <div class="col-12">
        <h3>Список сотрудников</h3>
      </div>

      <div class="col-12">
        <p>У вас еще нет добавленных сотрудников.</p>
      </div>
      <router-link to="/employers/adduser" class="btn btn-bg">Добавить сотрудника</router-link>
    </div>

  </div>







  <div class="wrapper list_wrapper" v-else>

    <div class="row">
      <h3>Список сотрудников</h3>
    </div>


    <div class="row space-between align-center" v-if="usersList.length">
      <div class="col-sm-4">
        <router-link to="/employers/adduser" class="add">+ Добавить сотрудника</router-link>
      </div>
      <div class="col-sm-8 search">
        <div class="row text-right">
          <input name="query" v-model="searchQuery" placeholder="Введите ФИО, электронную почту">
          <button class="btn btn-bg search-form__btn">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="row" v-else>
      <div class="col-12">
        <p>У вас еще нет добавленных сотрудников или филилалов</p>
      </div>
      <router-link to="/employers/adduser" class="btn btn-bg">Добавить сотрудника</router-link>
    </div>


    <VuetifyDataTable
        :columns="tableData.columns"
        :searchQuery="searchQuery"
        :page="page"
        :addUrl="'/employers/adduser/'"
        :itemsPerPage="itemsPerPage"
        :items="usersList"
        :pageCount="pageCount"
        @change="changePage($event)"
        @changePage="changePageNum($event)"
        @changeItems="changeItems($event)" />

  </div>
</template>

<script>

import VuetifyDataTable from "@/components/VuetifyDataTable";
export default {
  name: "Employers",
  components: {VuetifyDataTable},
  data() {
    return {
      showEmployers: true,
      searchQuery: '',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,

      tableData: {
        columns: [
          {
            text: "ФИО",
            sortable: true,
            value: "fio"
          },
          {
            text: "Электронная почта",
            sortable: false,
            value: "email"
          },
          {
            text: "Филиал",
            value: "filialName"
          },
          {
            text: 'Система и роль',
            class: "ff",
            cellClass: "dddd",
            sortable: false,
            value: "system"
          },
          {
            text: "",
            sortable: false,
            value: "status"
          },
        ],
        displayNames: {
          'fio': "ФИО",
          email: "Электронная почта",
          filialName: "Филиал",
          system: "Система",
          role: "Роль",
          id: "",
          userId: "",
          superadmin: "",
          filialId: "",
          status: ""
        },
        notSort: [
          'email', 'role', 'status'
        ],
        columnsToDisplay: ['fio', 'email', 'filialName', 'system', 'role', 'status']
      }
    }
  },
  computed: {
    usersList() {
      return this.$store.getters.usersList;
    },
  },
  mounted() {
    this.$store.dispatch('getUsers');
  },
  methods: {
    changeItems(evt) {
      this.itemsPerPage = parseInt(evt.target.value);
    },
    changePageNum(evt) {
      this.page = evt;
    },
    changePage(evt) {
      this.pageCount = evt;
    }
  },
}
</script>


<style scoped lang="scss">

h3 {
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 32px;
}

p {
  margin-bottom: 56px;
}


.btn {
  width: 100%;
  max-width: 350px;
}

.list_wrapper {
  font-size: 18px;
  line-height: normal;

  h3 {
    margin-bottom: 48px;
  }


  .search {
    position: relative;

    .row {
      padding-right: 65px !important;
    }
    input {
      width: 100%;
      color: $text-gray;
      border-radius: 8px;
      border: solid 1px #e6e6e6;
      padding: 17px;
      padding-left: 20px;
      font-size: 18px;
      vertical-align: middle;
    }

    input:focus {
      outline: none;
      border-color: $navy;
      color: $navy;
    }

    .btn {
      position: absolute;
      width: auto;
      height: 100%;
      right: 0px;
      padding: 18px;
    }
  }
}



@include md-max {
  h3 {
    font-size: 18px;
    line-height: 1.5;
    margin-top: 15px;
  }


  .list_wrapper h3 {
    margin-bottom: 15px;
  }

  p {
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 40px;
  }


  .btn {
    width: 100%;
    max-width: 100%;
  }

  .list_wrapper .search {
    .row {
      padding-right: 65px !important;
      margin-top: 15px;
    }
    input {
      padding: 13px;
      padding-left: 15px;
      font-size: 16px;
    }


    .btn {
      padding: 15px;
      height: auto;
    }
  }
}

</style>
